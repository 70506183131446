import React from "react"
import {motion, useMotionValue, useTransform} from "framer-motion"

export default function SuccessCheck() {

    return (
        <div className={'check-container'}>
            <div className="success-checkmark">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
        </div>
    )
}
