import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    Input, Stack,
    styled,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import ReactWordcloud from "react-wordcloud";
import {firebase} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import arrow from '../../assets/arrow.svg'
import wordCheck from "../../functions/wordCheck";
import SuccessCheck from "../SuccessCheck";
import {useMotionValue, motion} from "framer-motion";

const parole = ["Amicizia","Fiducia","Futuro","Impegno","Orgoglio","Responsabilità","Sorriso"]

export default function WordcloudQuestion({tipo,sendAnswer, success, answered, sending,elementId}){
    const {id} = useParams()
    const [word, setWord] = useState('')
    const [spazio, setSpazio] = useState(false)
    const theme = useTheme()

    let progress = useMotionValue(90)

    const handleSetWord = (value) => {
      setWord(value)
    }

    function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    const handleSendAnswer = () => {
        let w = capitalizeTheFirstLetterOfEachWord(word).trim()

        setSpazio(false)
        /*if(w.includes(' ')){
            setSpazio(true)
        } else {*/
            if(wordCheck(w)){
                sendAnswer(w)
                setWord('')
            } else {
                setWord('')
            }
        /*}*/

    }

    return(
        <Stack sx={{position:'relative'}} justifyContent={"start"} alignItems={"start"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            {(!success) && <Box sx={{width: '100%', mt:0}}>
                <FormControl variant="standard" style={{width: '100%'}}>
                    <Stack direction={'row'} sx={{flexWrap:'wrap'}} justifyContent={'center'}>
                        {
                            parole.map((p) =>
                                <Box
                                    sx={{
                                        borderRadius: '0.7rem',
                                        background:word === p ? '#6cb1e2' : '#0c133b',
                                        padding:'0.5rem 1rem',
                                        margin: '0.4rem',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setWord(p)}
                                >
                                    <Typography color={word === p ? "#0c133b" : "white"}>
                                        {p}
                                    </Typography>
                                </Box>
                            )
                        }
                    </Stack>
                    {word !=='' && <Box sx={{mt: 3, mb:4}} >
                        <Typography color={'#0c133b'} fontSize={20}>
                            Hai scelto:
                        </Typography>
                        <Typography fontSize={35} color={'#0c133b'}>{word}</Typography>
                    </Box>}
                </FormControl>
                {spazio &&
                    <Typography sx={{mt: 1}}>Puoi inviare solo una parola</Typography>
                }
                <Button disabled={word.length<2} sx={{mt: 4}} variant={'wordcloud'} onClick={handleSendAnswer}>Invia</Button>
            </Box>}
            {(success || answered) &&
                <Box style={{width:'100%'}}>
                    <SuccessCheck/>
                </Box>
            }
        </Stack>
    )
}
