import {createTheme} from "@mui/material";
import bkg from '../assets/images/evento/bluvacanze/bkg.jpg'

const clap1 = '#0c133b'
const clap2 = '#0c133b'
const siNo1='#3a4de4'
const siNo2='#00d8a1'
const sentiment1='#3a4de4'
const sentiment2='#00d8a1'

const pleshDark = createTheme({

    typography: {
      fontFamily: ['Carnero']
    },
    components:{
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: `url(${bkg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize:'cover'
                },
            },
        },
        MuiDialog:{
            styleOverrides:{
                paper:{
                    backgroundImage: `url(${bkg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize:'cover'
                }
            }
        },
        MuiTextField:{
            styleOverrides:{
                root:{
                    '& fieldset':{
                        borderWidth:'3px !important',
                        borderRadius:'1rem'
                    }
                }
            }
        },
        MuiButton:{
            variants: [
                //siNo
                {
                    props: {variant:'siNo'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'si'},
                    style:{
                        background: 'linear-gradient('+siNo1+', '+siNo1+')',
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'no'},
                    style:{
                        background: 'linear-gradient('+siNo2+', '+siNo2+')',
                    },
                },
                //sentiment
                {
                    props: {variant:'sentiment'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'daccordo'},
                    style:{
                        background: 'linear-gradient('+sentiment1+', '+sentiment1+')',
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'nonDaccordo'},
                    style:{
                        background: 'linear-gradient('+sentiment2+', '+sentiment2+')',
                    },
                },
                {
                    props: {variant: 'clap'},
                    style:{
                        height: '100px',
                        width:'80%',
                        fontSize: '18px',
                        background: 'linear-gradient(90deg, '+clap1+' 0%, '+clap2+')',
                        border:'none',
                        color: '#f1ebe9',
                        borderRadius: '2rem',
                        borderWidth: '3px',
                    }
                },
                {
                    props: {variant: 'wordcloud'},
                    style:{
                        background: 'linear-gradient(90deg, #0c133b 0%, #0c133b)',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#fff',
                        borderRadius: '2rem',width: '100%', height: '50px',
                        '&:disabled':{
                            color: 'rgba(255,255,255,0.43)',
                            opacity: 0.7
                        }
                    }
                },
                {
                    props: {variant: 'qna'},
                    style:{
                        background: 'linear-gradient(90deg, #EA4D95 0%, #E76E51)',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#000',
                        borderRadius: '2rem',width: '100%', height: '50px'
                    }
                }
            ]
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#6cb1e2',
        },
        secondary: {
            main: '#263238',
        },
        accent: {
            main: '#EA4D95',
        },
        text: {
            primary: '#eceff1',
        }
    },
});

export default pleshDark
